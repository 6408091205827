import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SEO from '../components/seo';
import useFaqs from '../hooks/use-faqs';
import Layout from '../components/layout';
import { parseFAQ } from '../components/utitlies/utility';

const yellowHeading = css`
  background: rgb(249, 200, 3);
  line-height: 44px;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
`;

const accordionButton = css`
  margin: 3px 0;
  &:active,
  &:focus {
    outline: dotted 2px !important;
    outline-offset: 2px !important;
  }
`;

const eventContact = css`
  margin-top: 50px;
`;

export default function Faq() {
  const allFaqs = useFaqs();
  return (
    <Layout>
      <SEO title='Frequently Asked Questions' />
      <div className='grid-container'>
        <h2 css={yellowHeading}>FREQUENTLY ASKED QUESTIONS (FAQs)</h2>
        <div className='grid-row'>
          <div className='grid-col-12'>
            <section>
              <div className='usa-accordion'>
                {allFaqs.length > 0 ? (
                  allFaqs.map((faq) => (
                    <React.Fragment key={faq.id}>
                      <button
                        id={`btn_${faq.id}`}
                        className='usa-accordion__button'
                        aria-expanded='false'
                        aria-controls={`ctl_${faq.id}`}
                        css={accordionButton}
                      >
                        {faq.question}
                      </button>
                      <div
                        id={`ctl_${faq.id}`}
                        className='usa-accordion__content'
                        hidden={true}
                        dangerouslySetInnerHTML={{ __html: parseFAQ(faq.answer) }}
                      ></div>
                    </React.Fragment>
                  ))
                ) : (
                  <p>Sorry no FAQs, please check back later.</p>
                )}
              </div>
            </section>
            <section>
              <div>
                <div css={eventContact}>
                  <h4>Contact us:</h4>
                  <address>
                    (210) 221-7380 | DSN: 471-7380 <br />
                    3630 Stanley Rd <br />
                    JBSA Fort Sam Houston, TX 78234 <br />
                  </address>
                  <a href='mailto:usarmy.jbsa.medcom-ameddcs.mbx.dcomm@mail.mil'>
                    usarmy.jbsa.medcom-ameddcs.mbx.dcomm@mail.mil
                  </a>

                  <p>
                    For assistance, please submit questions to the Request For Information
                    (RFI) mailbox at:
                    <br />
                    <a href='mailto:medcoe_requestinfo@army.mil'>
                      medcoe_requestinfo@army.mil
                    </a>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
}
